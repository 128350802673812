import React from 'react';
import styles from './Header.module.css'
import { Link, useParams } from 'react-router-dom';
import siteMap, { SitePage, blankPage } from '../Model/siteMap';


interface HeaderProps {
}

const Header: React.FC<HeaderProps> = () => {
    const { id } = useParams();
    const page = siteMap.pages.find(p => p.id === id)

    const pageTitle = page ? ` /&nbsp${page.title}` : ""

    return (
        <div className={styles.container}>
            <Link to={`/`} key={'root'}><div className={styles.title}>colordodge</div></Link>
            <div className={styles.pageTitle} dangerouslySetInnerHTML={{ __html: pageTitle }}></div>
        </div>
    );
};

export default Header;
