import React, { CSSProperties } from 'react';
import styles from './PageView.module.css'
import { SitePage, blankPage } from '../Model/siteMap';
import siteMap from '../Model/siteMap';
import { useParams } from 'react-router-dom';

interface PageViewProps {
}

const PageView: React.FC<PageViewProps> = () => {

    const { id } = useParams();
    const page: SitePage = siteMap.pages.find(p => p.id === id) ?? blankPage
    const demo = page.demoLink ? <a href={page.demoLink} target="_blank">{page.demoLinkTitle}</a> : null

    const images = page.images.map(imageName => {
        const path = `/images/${page.id}/${imageName}`
        return (
            <div className={styles.imageView} key={"id-" + imageName}>
                <img className={styles.img} src={path} alt={path} />
            </div>
        )
    })

    return (

        <div className={styles.container}>
            <div className={styles.detail}>
                {page.description}
                <br />
                <br />
                {demo}
            </div>
            <div className={styles.images}>{images}</div>
        </div>
    );
};

export default PageView;
