import React from 'react';
import styles from './PortfolioGrid.module.css'
import siteMap from '../Model/siteMap';
import EntryView from './EntryView';

interface PortfolioGridProps {
    // Define any props for the component here.
    // For example: 
    // items?: string[];
}

const PortfolioGrid: React.FC<PortfolioGridProps> = (props) => {
    // Component logic goes here

    const entryViews = siteMap.pages.map(page => {
        return <EntryView key={page.id} page={page} />
    })

    return (
        <div className={styles.container}>
            {entryViews}
        </div>
    );
};

export default PortfolioGrid;
