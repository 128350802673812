export interface SiteMap {
    pages: SitePage[]
}

export interface SitePage {
    id: string
    title: string
    description: string
    images: string[]
    demoLink?: string
    demoLinkTitle?: string
}

const kinetiform: SitePage = {
    id: 'kinetiform-app',
    title: 'Kinetiform iOS App',
    description: `Kinetiform is doodling reimagined. It is a canvas with a mind of its own. Draw abstract shapes that automatically connect and fill with randomly generated patterns. Use Auto Draw to sit back and watch as the app creates a unique random composition right before your eyes. Watch as your drawing is tumbled and twisted through beautiful kaleidoscopic animations.`,
    images: ["2732x2048bb-1.png", "2732x2048bb-2.png", "2732x2048bb-3.png", "2732x2048bb-4.png", "2732x2048bb-5.png", "2732x2048bb-6.png", "2732x2048bb-7.png", "2732x2048bb-8.png", "2732x2048bb-9.png", "2732x2048bb.png"],
    demoLink: "https://apps.apple.com/us/app/kinetiform/id1599140822",
    demoLinkTitle: "View App"
}

const generativeIllustrations: SitePage = {
    id: 'generative-illustrations',
    title: "Generative Illustrations",
    description: "Created in Processing. Fully procedural illustrations using a map system to detect shapes. A line wanders around the canvas and detects collisions. When collisions are found, a map graph is used to detect the polygon that was formed and generative algorithms are used to create fill patterns.",
    images: ["PolyDraw14-hero.jpg", "PolyDraw01.jpg", "PolyDraw02.jpg", "PolyDraw03.jpg", "PolyDraw04.jpg", "PolyDraw05.jpg", "PolyDraw06.jpg", "PolyDraw07.jpg", "PolyDraw08.jpg", "PolyDraw09.jpg", "PolyDraw10.jpg", "PolyDraw11.jpg", "PolyDraw12.jpg", "PolyDraw13.jpg", "PolyDraw15.jpg", "PolyDraw16.jpg", "PolyDraw17.jpg", "PolyDraw18.jpg", "PolyDraw19.jpg"],
}

const gpuParticles: SitePage = {
    id: 'gpu-particles',
    title: 'GPU Particles',
    description: 'A particle engine written in WebGL that uses shaders to process all particle motion. 200,000 particles are simulated in the browser. 8 shaders provide different animation modes that can be accessed via the number keys.',
    images: ["GPUParticles01.jpg", "GPUParticles02.jpg", "GPUParticles03.jpg", "GPUParticles04.jpg", "GPUParticles05.jpg", "GPUParticles06.jpg", "GPUParticles07.jpg", "GPUParticles08-hero.jpg"],
    demoLink: "/GPUParticles/",
    demoLinkTitle: "View Demo",
}

const proceduralPlanets: SitePage = {
    id: 'procedural-planets',
    title: 'Procedural Planets',
    description: 'Written in WebGL using three.js. Planet, nebulae, and stars are all generated procedurally using shaders. Textures are generated for a height map, moisture map, texture map, normal map, and roughness map. A biome lookup image is generated to control the distribution of colors. Unique names are generated and used as the random seed, which is then added to the URL string. If you find a cool planet, you can just share the link, and the planet you made will be shared.',
    images: ["Planet01.jpg", "Planet02.jpg", "Planet03.jpg", "Planet04.jpg", "Planet05.jpg", "Planet07.jpg", "Planet08.jpg", "Planet09.jpg", "Planet10.jpg", "Planet11.jpg", "Planet12.jpg", "Planet13.jpg", "Planet14.jpg", "Planet15.jpg", "Planet16.jpg", "Planet18.jpg", "Planet19.jpg", "Planet20.jpg", "Planet21.jpg", "Planet22.jpg", "Planet23-1024x640.jpg", "Planet24.jpg"],
    demoLink: "/ProceduralPlanet/",
    demoLinkTitle: "View Demo"
}

const proceduralCity: SitePage = {
    id: 'procedural-city',
    title: 'Procedural City',
    description: 'Fly through a cityscape built entirely from algorithms. Each building is randomly generated by manipulating cube geometry. The position, size, and color of the buildings are generated from simplex noise maps. This was built for WebGL using the Three.js library.',
    images: ["Cityscape-1.jpg", "Cityscape-2-1024x730.jpg", "Cityscape-3.jpg", "Cityscape-4.jpg", "Cityscape-5.jpg", "Cityscape-6.jpg", "Cityscape-7.jpg", "Cityscape-8.jpg"],
    demoLink: "/ProceduralCity/",
    demoLinkTitle: "View Demo",
}

const perlinShader: SitePage = {
    id: 'perlin-shader',
    title: 'Perlin Shader',
    description: 'Perlin noise can be used to create a wide variety of imagery. Here we use a fragment shader and Perlin noise to divide a canvas into regions and render different noise patterns in each. The resulting visuals are totally random and constantly evolving. You can also save high-res screenshots from the controls. This shader was written in GLSL and presented with Three.js. Javascript was used to track and evolve the individual regions.',
    images: ["PerlinShader-1.jpg", "PerlinShader-10.jpg", "PerlinShader-11.jpg", "PerlinShader-12.jpg", "PerlinShader-13.jpg", "PerlinShader-2.jpg", "PerlinShader-3.jpg", "PerlinShader-4.jpg", "PerlinShader-5.jpg", "PerlinShader-6-1024x677.jpg", "PerlinShader-7.jpg", "PerlinShader-8.jpg", "PerlinShader-9.jpg"],
    demoLink: "/PerlinShader/",
    demoLinkTitle: "View Demo",
}

const polygonSubdivision: SitePage = {
    id: 'polygon-subdivision',
    title: 'Polygon Subdivision',
    description: 'Arbitrary 4-sided polygons are randomly subdivided until a specific size threshold is met. The color of the resulting subdivisions is calculated based on slight deviations to the parent polygon. Made with P5.js.',
    images: ["PolygonSubdivision-1506618358895.jpg", "PolygonSubdivision-1506618371573.jpg", "PolygonSubdivision-1506618436036.jpg", "PolygonSubdivision-1506618445678.jpg", "PolygonSubdivision-1506618503265.jpg", "PolygonSubdivision-1506618536509.jpg", "PolygonSubdivision-1506618568943.jpg", "PolygonSubdivision-1506618587888.jpg", "PolygonSubdivision-1506618642249-1024x724.jpg", "PolygonSubdivision-1506618760012.jpg"],
    demoLink: "/PolygonSubdivision/",
    demoLinkTitle: "View Demo",
}

const reactionDiffusion: SitePage = {
    id: 'reaction-diffusion',
    title: 'Reaction Diffusion',
    description: 'A simulation of two chemicals reacting in a fluid written in GLSL and presented using Three.js.  This experiment was adapted from Daniel Shiffman’s reaction diffusion in Processing tutorial, which was in turn adapted from Karl Sim’s excellent description of the process.',
    images: ["ReactionDiffusion-11.png", "ReactionDiffusion-13.png", "ReactionDiffusion-16.png", "ReactionDiffusion-20.png", "ReactionDiffusion-22.png", "ReactionDiffusion-24.png", "ReactionDiffusion-27.png", "ReactionDiffusion-3.png", "ReactionDiffusion-30.png", "ReactionDiffusion-32.png", "ReactionDiffusion-35.png", "ReactionDiffusion-36.png", "ReactionDiffusion-39.png", "ReactionDiffusion-41.png", "ReactionDiffusion-5.png", "ReactionDiffusion-8.png"],
    demoLink: "/ReactionDiffusion/",
    demoLinkTitle: "View Demo",
}

const webglPolygon: SitePage = {
    id: 'polygon-chain',
    title: 'WebGL Polygon Chain',
    description: 'We’re back in WebGL and Three.js again. We’ve got 300 polygons moving in an oscillating chain. Basic horizontal and vertical symmetry add the special sauce.',
    images: ["Polychain-1.png", "Polychain-11.png", "Polychain-15.png", "Polychain-18-1024x576.png", "Polychain-3.png", "Polychain-7.png"],
    demoLink: "/Polychain/",
    demoLinkTitle: "View Demo"
}

const webglKaleidoscope: SitePage = {
    id: 'webgl-kaleidoscope',
    title: 'WebGL Kaleidoscope',
    description: 'WebGL is a super-powerful tool for creative expression on the web, and three.js makes an otherwise obtuse system a pleasure to work with. Here is a kaleidoscope created with WebGL via three.js. It has a number of configurable features including the number of axis and the type of grid used. It also uses environment mapping for a nice reflective effect.',
    images: ["kaleidoscope1-1024x575.jpg", "kaleidoscope2.jpg", "kaleidoscope3.jpg"],
    demoLink: "/Kaleidoscope/",
    demoLinkTitle: "View Demo",
}

const vertexOscillation: SitePage = {
    id: 'vertex-oscillation',
    title: 'Vertex Oscillation',
    description: 'Layering a few oscillations can produce dramatic effects. This project uses vertex and fragment shaders written in GLSL with a custom Three.js shader. We begin with a sphere and then send multiple compound waves through each axis via a vertex shader. The fragment shader then uses the offset values to map a color to each vertex. This demo give you control over each modulation wave an the colors used to represent the offsets.',
    images: ["VertexOsc1.jpg", "VertexOsc2.jpg", "VertexOsc3.jpg", "VertexOsc4.jpg", "VertexOsc5-1024x639.jpg", "VertexOsc6.jpg"],
    demoLink: "/VertexOscillation/",
    demoLinkTitle: "View Demo",
}

const beautifulProgramming: SitePage = {
    id: 'beautiful-programming',
    title: 'Beautiful Programming',
    description: "Beautiful Programming is a collection of generative design experiments made with Processing. I’ve always been fascinated by generative design. At an early age I discovered my grandfather’s old spirograph set in the back corners of a dusty attic. I was amazed at how the combinations of mathematical relationships created different patterns. I’ve since learned how to create these effects using only computer code. What started as a fascination has turned into a life long passion.  These sketches are the descendants of the tiny spirograph doodles I made as a boy.",
    images: ["InfiniteArboretum1.jpg", "CosmicSoup2.jpg", "CubicSunset3.jpg", "PisciumDraconis1.jpg", "descentOfColor4.jpg"],
}

const uzuApp: SitePage = {
    id: 'uzu-ios',
    title: 'Uzu iOS App',
    description: `What started out as a personal project quickly turned into a full blown iPad app. Uzu is a “Kinetic Multitouch Particle Visualizer”, and it was the first iPad app to utilize all 10 fingers for interaction. Using a custom physics-based particle engine and OpenGL ES, Uzu allows users to manipulate a fully dynamic art light show. The engine is fully customizable and allows users to explore and create their own presets. Uzu won Apple’s App of the Week and was included in Apple’s 2010 App Hall of Fame.`,
    images: ["UzuBack44.jpg", "UzuBack01.jpg", "UzuBack05.jpg", "UzuBack06.jpg", "UzuBack07.jpg", "UzuBack14.jpg", "UzuBack17.jpg", "UzuBack23.jpg", "UzuBack26.jpg", "UzuBack30.jpg", "UzuBack32.jpg"],
    demoLink: "https://apps.apple.com/us/app/uzu-an-interactive-light-show/id376551723",
    demoLinkTitle: "View App"
}

const creativeConfidence: SitePage = {
    id: 'creative-confidence',
    title: 'Creative Confidence',
    description: `While working at IDEO, I had the great opportunity to help with the NYC launch party for the book “Creative Confidence” by Tom and David Kelly.  I built some software using processing that simulated painting with a brush. To generate the colors, images were loaded from Instagram that were tagged with #creativeconfidence. Attendees of the party could sit down and paint pictures from the party. A second computer projected images on the wall that were painted dynamically by a brush simulation particle engine. 10 different effects animated and painted while loading various images of the party.`,
    images: ["cc2-1024x1018.jpg", "cc1.jpg", "cc3.jpg", "cc4.jpg", "cc5.jpg", "cc6.jpg", "cc7.jpg", "cc8.jpg"],
}

const infinityMirror: SitePage = {
    id: 'infinity-mirror',
    title: 'Infinity Mirror',
    description: `I love flashing lights. All the better if I get to control them. 1 Arduino Mega and 10 meters of RGB LEDs later and we have a prototype for a wall of LED light strips. The eventual hope is to turn the entire thing into an infinity mirror. Then we just sit and stare for hours. It will be great.`,
    images: ["ledWallComplete1-1024x789.jpg", "ledWall2.jpg", "ledWall3.jpg", "ledWall4.jpg", "ledWall5.jpg", "ledWall6.jpg", "ledWallComplete2.jpg", "ledWallComplete3.jpg", "ledWallDiagram.jpg"],
}

const masonryColors: SitePage = {
    id: 'masonry-colors',
    title: 'Masonry Colors',
    description: `While making a prototype using the javascript library Masonry, I came across the full list of CSS colors. The number of colors and the strange names some of them had facinated me, so I chose to integrate them into the demonstration. This prototype UI grabs 5 random colors from the full list of CSS color names, you can then infinite scroll through content and select only the content of a specific color. There is a randomize button in the upper right to select 5 new random colors. Its fun.`,
    images: ["masonry3-1024x682.jpg", "masonry1.jpg", "masonry2.jpg", "masonry4.jpg", "masonry5.jpg", "masonry6.jpg", "masonry7.jpg", "masonry8.jpg"],
}

const instagramGrid: SitePage = {
    id: 'instagram-grid',
    title: 'Instagram Grid',
    description: `I wanted to see if I could make an animated grid of Instagram photos using javascirpt and a little jQuery. It turns out I could, but the real point of the exercise was to see how easy it was to use the Instagram API. This prototype has a rudimentary UI that allows you to change the tag search. You can also control the size of the grid and animation speed.`,
    images: ["instagram1.jpg", "instagram2.jpg", "instagram3.jpg", "instagram4-1024x593.jpg"],
}

const actionscriptLab: SitePage = {
    id: 'actionscript-lab',
    title: 'Actionscript Lab',
    description: `Colordoge.com is a personal website. It is a place where I created a number of visual experiments in Actionscript 3. The project represents some of my favorite explorations of visual creativity and demonstrates a number of useful dynamic animation techniques. In a way, this site was the precursor to Uzu, as many of the techniques I use for particle engines and animation came from what I learned during these projects.`,
    images: ["labs4-1024x885.jpg", "labs2.jpg", "labs3.jpg", "labs5.jpg", "labs6.jpg"],
}

const coconutRun: SitePage = {
    id: 'coconut-run',
    title: 'Coconut Run',
    description: `Johnson Controls hired Colossal Squid Industries to create a new web presence. As part of the site, three games were to be developed to help drive the primary marketing goals – the first game was Coconut Run. In Coconut Run, users can draw their own car and use it to transport coconuts across a challenging terrain. I created the concept, and worked closely with a designer and illustrator, building a robust game that integrated the Box2D physics engine for realistic and fun game play. The game was a viral success, and soon many youtube videos appeared showing off the impressive and hilarious variety of vehicles users had created.`,
    images: ["coconutRun1.jpg"],
}

const littleGreenie: SitePage = {
    id: 'little-greenie',
    title: 'Little Greenie',
    description: `When Scott hired Colossal Squid Industries to build a new web presence, they wanted an interactive web app that would help encourage users to be more “green”. Working with the overall Scott team, I designed and built Little Greenie: a flash-based web app that allows users to choose green promises and plant them as trees in an interactive environment. The engine is a fully realized dynamic genetic plant growing system that contains over a hundred varieties of plants. I created the high variety of plants by having the engine generate random variety, and then choosing the best variations. The entire app content is xml driven and integrates with facebook and twitter.`,
    images: ["littleGreenie1.jpg", "littleGreenie2.jpg", "littleGreenieTree1.jpg", "littleGreenieTree2.jpg", "littleGreenieTree3.jpg", "littleGreenieTree4.jpg", "littleGreenieTree5.jpg", "littleGreenieTree6.jpg", "littleGreenieTree7.jpg", "littleGreenieTree8.jpg", "littleGreenieTree9.jpg"],
}

const rootbeerChallange: SitePage = {
    id: 'root-beer',
    title: 'Rootbeer Challenge',
    description: `Root Beer Challenge started off as a root beer taste test. The members of Colossal Squid Industries fancied themselves purveyors of the fine taste of root beer, and thus a “fun project” was born. In an effort to document the event I designed and built a website full of all the charm and wit timelessly associated with root beer. The design includes a tremendous amount of compositing in Photoshop, combined with a fun interface and fluid animation. It was basically the steam punk website I had always wanted to create. The site became an ongoing project, with multiple members of the team contributing assets.`,
    images: ["rbc1.jpg", "rbc2.jpg", "rbc3.jpg", "rbc4.jpg", "rbc5.jpg"],
}

const hungerland: SitePage = {
    id: 'hungerland',
    title: 'Hungerland',
    description: `While working at Pixel, the creative team was tasked with creating a fun web presence for the Ball Park Franks brand. Hunger Land is a top-down driving game used to explore a world of fun surprises. Users drive a number of vehicles across a crazy world containing deserts, swamps, forests, plains, and ice worlds. I created the initial concept and layout, and I worked with an illustrator to create the map for the fictional Hunger Land. I also built the site – including custom AI, a tunable driving engine, and multiple particle engines.`,
    images: ["hungerland1.jpg", "hungerland2.jpg", "hungerland3.jpg", "hungerland4.jpg", "hungerland5.jpg"],
}

const particleBuilder: SitePage = {
    id: 'particle-builder',
    title: 'Particle Flow Builder',
    description: `The second game in the Johnson Control’s trilogy is Particle Pro. Particle Pro allows you to take control over the energy use of a home by directing the flow of particles using various tools. The Box2d engine is again used as the base for the physics engine, and a number of tools were created to bounce, funnel, slow, accelerate, and redirect particles. I contributed to the concept and worked with a designer and illustrator to build the final product.`,
    images: ["particlePro1.jpg", "particlePro2.jpg", "particlePro3.jpg"],
}

const piggyGame: SitePage = {
    id: 'piggy-game',
    title: 'Piggy Contraption',
    description: `The third and final game in the Johnson Controls series has you tossing a pig around your own fantastic contraption. The game asks users to get a pig to the top of a tower by drawing with tools that do a variety of things: platforms, launch pads, cushions, conveyor belts, and trampolines. Box2D is again used for the physics engine, and an another team member provided the graphic design and illustration. Of the three Johnson Control games, this one is by far the most hilarious.`,
    images: ["piggy1.jpg", "piggy2.jpg", "piggy3.jpg"],
}

const darkKnight: SitePage = {
    id: 'dark-knight',
    title: 'Dark Knight Toys',
    description: `While working at Pixel, I was excited to be a part of the launch of the toy campaign for “The Dark Knight”. Mattel wanted a banner campaign that would engage children and help them learn about the new toys surrounding the Movie. I designed and created a banner bursting with animation and flair. The client was extremely pleased and the campaign generated 4 times the normal number of impressions.`,
    images: ["darkKnight1.jpg", "darkKnight2.jpg"],
}

const legoCastle: SitePage = {
    id: 'lego-castle',
    title: 'LEGO Castle',
    description: `Lego wanted Pixel to create a banner campaign highlighting the Lego Castle brand, and bringing attention to the game Lego created to go along with the series. I designed and build an in-banner game based off the assets from the original. The result was a tiny, fun, and addicting web game that gave Lego Castle the attention it deserved. Click the catapult to launch boulders at the approaching army – survive as long as you can.`,
    images: ["legoCastle3.jpg", "legoCastle4.jpg"],
}

const marsMission: SitePage = {
    id: 'mars-mission',
    title: 'LEGO Mars Mission',
    description: `Lego again came to Pixel wanting a fun in-banner version of a game they created for their Lego Mars Mission brand. Using the assets from the original Mars Mission game, I designed and built a small banner game that let users shoot at passing waves of enemies. The project made heavy use of trigonometry to dictate the paths of the AI vehicles. Point and shoot.`,
    images: ["marsMission.jpg", "marsMission2.jpg"],
}

export const blankPage: SitePage = {
    id: '',
    title: '',
    description: '',
    images: [],
}

const siteMap: SiteMap = {
    pages: [
        uzuApp,
        kinetiform,
        generativeIllustrations,
        gpuParticles,
        proceduralPlanets,
        proceduralCity,
        perlinShader,
        polygonSubdivision,
        reactionDiffusion,
        webglPolygon,
        webglKaleidoscope,
        vertexOscillation,
        infinityMirror,
        beautifulProgramming,
        creativeConfidence,
        masonryColors,
        instagramGrid,
        actionscriptLab,
        coconutRun,
        littleGreenie,
        rootbeerChallange,
        hungerland,
        particleBuilder,
        piggyGame,
        darkKnight,
        legoCastle,
        marsMission
    ]
}

export default siteMap