import React, { CSSProperties } from 'react';
import styles from './EntryView.module.css'
import { SitePage } from '../Model/siteMap';
import { Link } from 'react-router-dom';

interface EntryViewProps {
    page: SitePage
}

const EntryView: React.FC<EntryViewProps> = ({ page }) => {


    const heroImageName = page.images[0]
    const path = `/images/${page.id}/${heroImageName}`
    const heroImage = <div className={styles.imageView}><img className={styles.img} src={path} alt={path} /></div>

    const demo = page.demoLink ? <a href={page.demoLink} target="_blank">{page.demoLinkTitle}</a> : null

    return (
        <div className={styles.container}>
            <Link to={`/${page.id}`} key={page.id}>
                {heroImage}
            </Link>

            <div className={styles.detailsHolder}>
                <div className={styles.details}>
                    <div className={styles.title}>{page.title}</div>
                    <div className={styles.demoLink}>{demo}</div>
                </div>
            </div>



        </div>
    );
};

export default EntryView;
