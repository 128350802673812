import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';
import styles from "./App.module.css";
import PortfolioGrid from './Views/PortfolioGrid';
import PageView from './Views/PageView'; // Make sure to create this component
import Header from './Views/Header';
import ScrollToTop from './Wrappers/ScrollToTop';

function App() {

  return (
    <Router>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={
            <div className={styles.app}>
              <Header />
              <PortfolioGrid />
            </div>
          } />
          <Route path="/:id" element={
            <div className={styles.app}>
              <Header />
              <PageView />
            </div>
          } />
        </Routes>
      </ScrollToTop>
    </Router>
  );
}

export default App;